import {
  Github,
  Twitter,
  Youtube,
  Linkedin,
  Instagram,
} from "@styled-icons/boxicons-logos"

const Icons = {
  Github,
  Twitter,
  Youtube,
  Linkedin,
  Instagram,
}

export default Icons
