export default {
  colors: {
    borders: "#555",
    texts: "#ddd",
    postColor: "#fff",
    highlight: "#9bcbff",
    mediumBackground: "#333333",
    background: "#222222",
    white: "#fff",
    black: "#222",
  },
}
