const links = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Sobre Mim",
    url: "/about",
  },
]

export default links
